<template>
    <div class="look-complete-data-box">
        <div class="look-complete-data">
            <div class="font-icon" @click="closeChildAlert">
                <span class="iconfont">&#xe67d;</span>
            </div>
            <div style="width 100%;height:240px;" id="lookCompleteDataZujian"></div>
        </div>
    </div>
</template>

<script>
// import addBgImages from '../../utils';
export default {
    data() {
        return {
            yyflList: [],
            yjflList: [],
            nameList: [],
        };
    },
    mounted() {
        this.salesanalysisGclx();
    },
    props: {
        selectTimeData: {
            type: Array,
        },
    },
    methods: {
        closeChildAlert() {
            this.$emit('closeChildAlert');
        },
        volumeComparisonFun() {
            const myChart = this.$echarts.init(document.getElementById('lookCompleteDataZujian'));
            const option = {
                legend: {
                    itemWidth: 8,
                    itemHeight: 8,
                },
                tooltip: {
                    data: ['已用方量', '预计方量'],
                },
                grid: {
                    top: '13%',
                    left: '3%',
                    right: '4%',
                    bottom: '-0%',
                    containLabel: true,
                },
                xAxis: {
                    type: 'category',
                    data: this.nameList,
                    axisLabel: {
                        color: '#666666',
                        fontSize: 10,
                    },
                    axisTick: {
                        show: false,
                    },
                    axisLine: {
                        lineStyle: {
                            color: '#D7D7D7',
                        },
                    },
                    splitLine: {
                        lineStyle: {
                            color: '#D7D7D7',
                            type: 'dashed',
                        },
                    },
                },
                yAxis: {
                    name: '单位(方)',
                    nameTextStyle: {
                        padding: [0, 0, -10, 45], // 四个数字分别为上右下左与原位置距离
                        color: '#979EA7',
                        fontSize: '10',
                    },
                    axisLabel: {
                        color: '#666666',
                        fontSize: 10,
                    },
                    axisTick: {
                        show: false,
                    },
                    axisLine: {
                        lineStyle: {
                            color: '#D7D7D7',
                        },
                    },
                    splitLine: {
                        lineStyle: {
                            color: '#D7D7D7',
                            type: 'dashed',
                        },
                    },
                },
                series: [
                    {
                        name: '已用方量',
                        data: this.yyflList,
                        type: 'bar',
                        showBackground: true,
                        backgroundStyle: {
                            color: 'rgba(220, 220, 220, 0.8)',
                        },
                        label: {
                            show: true,
                            position: 'top',
                            fontFamily: 'QuartzLTStd',
                            padding: 5,
                            color: '#4D7DE0',
                            // backgroundColor: {
                            //     image: addBgImages(1, 'top'),
                            // },
                        },
                        itemStyle: {
                            normal: {
                                color: new this.$echarts.graphic.LinearGradient(0, 1, 0, 0, [{
                                    offset: 0,
                                    color: '#3E78C3', // 0% 处的颜色
                                }, {
                                    offset: 1,
                                    color: '#44A1F5', // 100% 处的颜色
                                }], false),
                            },
                        },
                    },
                    {
                        name: '预计方量',
                        data: this.yjflList,
                        type: 'bar',
                        showBackground: true,
                        backgroundStyle: {
                            color: 'rgba(220, 220, 220, 0.8)',
                        },
                        label: {
                            show: true,
                            position: 'top',
                            fontFamily: 'QuartzLTStd',
                            padding: 5,
                            color: '#EA8344',
                            // backgroundColor: {
                            //     image: addBgImages(4, 'top'),
                            // },
                        },
                        itemStyle: {
                            normal: {
                                color: new this.$echarts.graphic.LinearGradient(0, 1, 0, 0, [{
                                    offset: 0,
                                    color: '#EA8243', // 0% 处的颜色
                                }, {
                                    offset: 1,
                                    color: '#F0AF56', // 100% 处的颜色
                                }], false),
                            },
                        },
                    },
                ],
            };
            myChart.setOption(option, true);
        },
        // 工程用量统计
        salesanalysisGclx() {
            this.$axios
                .get(`/interfaceApi/report/salesanalysis/gcyl/gclx?startDate=${this.selectTimeData[0]}&endDate=${this.selectTimeData[1]}`)
                .then(res => {
                    this.gcyltjData = res;
                    res.list.forEach(item => {
                        this.yjflList.push(item.yjfl);
                        this.yyflList.push(item.yyfl);
                        this.nameList.push(item.name);
                        return this.gcslTotal;
                    });
                    this.volumeComparisonFun();
                })
                .catch(err => {
                    console.log(err);
                });
        },
    },
};

</script>
<style lang='stylus' scoped>
.look-complete-data-box
    position fixed
    top 0
    left 0
    right 0
    bottom 0
    background rgba(0,0,0,0.4)
    align-items center
    justify-content center
    display flex
    z-index 99
    .look-complete-data
        background #fff
        width 90%
        padding 0 0 .4rem
        .font-icon
            width 100%
            display flex
            justify-content flex-end
            padding-right .6rem
            padding-top .2rem
            span
                width .3rem
                height .3rem
                display flex
                justify-content center
                align-items center
                color #1577D2
                border 1px solid #D7D7D7
                font-size .16rem
                cursor pointer
</style>